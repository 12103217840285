// Icons
import PinIcon from 'assets/icons/maps/pin.png';
import SelectedPinIcon from 'assets/icons/maps/selected-pin.png';
import { MAPBOX_PUBLIC_TOKEN } from 'gatsby-env-variables';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
// Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Map, MapRef, Marker, NavigationControl, Popup } from 'react-map-gl';

// Ui-Kit
import Button from 'ui-kit/button/button';
import MapViewIcon from 'ui-kit/illustrations/map-view/map-view';

import { PharmacyInfo, PharmacyResults } from 'types/discount-card';
import { CustomGeoJSONFeature, GeoJSONFeatureCollection } from 'types/mapbox';

// Utils
import { createGeoJSON, setBounds, translateToAddressesData } from 'util/mapbox';

import { MapViewProps } from './map-view.props';
import './map-view.styles.scss';

mapboxgl.accessToken = MAPBOX_PUBLIC_TOKEN;
mapboxgl.workerClass = MapboxWorker;

const MAPBOX_INITIAL_ZOOM = 11;
const MAX_MAP_ZOOM = 15;

const MapView: React.FC<MapViewProps> = ({
    selectedPharmacy,
    pharmacies,
    onSelectedPharmacy,
    isVisible,
    handleChangeMapVisible
}) => {
    const mapRef = useRef<MapRef>(null);
    const imgRefs = useRef<(HTMLImageElement | null)[]>([]);
    const [lng, setLng] = useState<number | null>(null);
    const [lat, setLat] = useState<number | null>(null);
    const [geoJSON, setGeoJSON] = useState<GeoJSONFeatureCollection | undefined>();
    const [popupInfo, setPopupInfo] = useState<CustomGeoJSONFeature | null>(null);

    const [selectedPinName, setSelectedPinName] = useState<string | null>(null);

    const centerMap = (coordinatesToCenter?: [number, number][]) => {
        const coordinates =
            coordinatesToCenter && coordinatesToCenter.length
                ? coordinatesToCenter
                : geoJSON?.features.map((feature) => feature.geometry.coordinates) || [];

        if (!coordinates.length) return;

        const bounds = coordinates.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );

        setTimeout(() => {
            mapRef.current?.fitBounds(bounds, {
                padding: { top: 50, left: 50, right: 50, bottom: 176 },
                maxZoom: MAX_MAP_ZOOM
            });
        }, 20);
    };

    const handleShowAllPharmacies = () => {
        setPharmacyData(pharmacies);
        setSelectedPinName(null);
        onSelectedPharmacy(undefined);
        resetAllPins();
        setPopupInfo(null);
    };

    const handleMarkerClick = (index: number) => {
        const clickedPharmacy = pharmacies.flatMap((pharmacy) => pharmacy.locations)[index];

        if (clickedPharmacy) {
            onSelectedPharmacy(clickedPharmacy);
        }
    };

    const updatePinAndSetNewOne = (index: number) => {
        setTimeout(() => {
            imgRefs.current.forEach((imgRef) => {
                if (imgRef) {
                    imgRef.src = PinIcon;
                }
            });

            if (imgRefs.current[index]) {
                imgRefs.current[index].src = SelectedPinIcon;
            }
            const feature = geoJSON?.features[index];
            if (feature) {
                centerMap([feature.geometry.coordinates]);
            }
        }, 20);
    };

    const resetAllPins = () => {
        setTimeout(() => {
            imgRefs.current.forEach((imgRef) => {
                if (imgRef) {
                    imgRef.src = PinIcon;
                }
            });
            setPopupInfo(null);
        }, 20);
    };

    const findPharmacyIndex = useCallback(
        (selectedPharmacy?: PharmacyInfo) => {
            if (!selectedPharmacy) return 0;

            return pharmacies
                .flatMap((pharmacy) => pharmacy.locations)
                .findIndex(
                    (pharmacyLocation) =>
                        pharmacyLocation.name === selectedPharmacy.name &&
                        pharmacyLocation.address === selectedPharmacy.address
                );
        },
        [pharmacies]
    );

    const toggleMapVisibility = () => {
        handleChangeMapVisible();
        centerMap();

        if (selectedPharmacy) {
            updatePinAndSetNewOne(findPharmacyIndex(selectedPharmacy));
        }
    };

    const setPharmacyData = (pharmacies: PharmacyResults[]) => {
        const formattedAddresses = translateToAddressesData(pharmacies);

        if (!formattedAddresses || !formattedAddresses.length) {
            setGeoJSON(undefined);
            return;
        }

        const featureCollection = createGeoJSON(formattedAddresses);
        setBounds(featureCollection, setLat, setLng);
        setGeoJSON(featureCollection);

        centerMap(featureCollection.features.map((feature) => feature.geometry.coordinates));
    };

    useEffect(() => {
        if (!pharmacies || pharmacies.length === 0) return;

        if (selectedPharmacy) {
            setSelectedPinName(selectedPharmacy.PharmacyName);
            const index = findPharmacyIndex(selectedPharmacy);
            updatePinAndSetNewOne(index);
            setPopupInfo(geoJSON?.features[index] || null);
        } else {
            handleShowAllPharmacies();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pharmacies, selectedPharmacy]);

    return isVisible && lat && lng ? (
        <div className="map-container">
            <div className="sidebar">
                <span>Showing all pharmacies in your area</span>

                <div className="sidebar-actions">
                    <Button
                        className="btn-text btn-text-blue"
                        label="Hide Map View"
                        type="button"
                        variant="text"
                        onClick={toggleMapVisibility}
                    />
                    {pharmacies && (
                        <Button
                            disabled={!selectedPinName}
                            label="Show All Pharmacies"
                            type="button"
                            className={`${selectedPinName ? 'is-active' : ''} small-button btn btn-outline`}
                            variant="outline-smoke"
                            onClick={handleShowAllPharmacies}
                        />
                    )}
                </div>
            </div>
            <Map
                id="displayMap"
                reuseMaps
                ref={mapRef}
                style={{ width: '100%', height: '100%' }}
                initialViewState={{
                    latitude: lat,
                    longitude: lng,
                    zoom: MAPBOX_INITIAL_ZOOM
                }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                mapboxAccessToken={MAPBOX_PUBLIC_TOKEN}
            >
                <NavigationControl position="top-right" />

                {geoJSON?.features.map((feature, index) => {
                    if (selectedPinName === null || feature.properties.name === selectedPinName) {
                        return (
                            <Marker
                                key={index}
                                longitude={feature.geometry.coordinates[0]}
                                latitude={feature.geometry.coordinates[1]}
                                anchor="bottom"
                                onClick={(e) => {
                                    e.originalEvent.stopPropagation();
                                    handleMarkerClick(index);
                                }}
                            >
                                <img
                                    ref={(el) => (imgRefs.current[index] = el)}
                                    src={selectedPinName === feature.properties.id ? SelectedPinIcon : PinIcon}
                                    alt="Pin"
                                    style={{
                                        width: 24,
                                        height: 24,
                                        cursor:
                                            !selectedPinName || selectedPinName === feature.properties.id
                                                ? 'pointer'
                                                : 'unset'
                                    }}
                                />
                            </Marker>
                        );
                    }
                    return null;
                })}

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={popupInfo.geometry.coordinates[0]}
                        latitude={popupInfo.geometry.coordinates[1]}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div className="popup-info">
                            <strong>{popupInfo.properties.name}</strong>
                            <br />
                            <span className="popup-info--content">{popupInfo.properties.address}</span>
                            <br />
                            <span className="popup-info--price">{popupInfo.properties.price}</span>
                        </div>
                    </Popup>
                )}
            </Map>
        </div>
    ) : (
        <div className="map-view">
            <MapViewIcon />
            <p>Show all the pharmacies on a map</p>
            <Button
                label="Open Map View"
                type="button"
                variant="outline-primary"
                className="small-button"
                onClick={toggleMapVisibility}
            />
        </div>
    );
};

export default MapView;

import { useEffect, useState } from 'react';

export enum Platform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    Linux = 'Linux',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    Windows = 'Windows',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    MacOS = 'MacOS',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    Android = 'Android'
}

export const usePlatform = () => {
    const [platform, setPlatform] = useState<Platform | undefined>(undefined);

    const isMobileDevice = (): boolean => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const userAgent = navigator.userAgent.toLowerCase();

            if (/(mac|iphone|ipod|ipad)/i.test(userAgent)) {
                setPlatform(Platform.MacOS);
            } else if (/windows/i.test(userAgent)) {
                setPlatform(Platform.Windows);
            } else if (/android/i.test(userAgent)) {
                setPlatform(Platform.Android);
            } else if (/linux/i.test(userAgent)) {
                setPlatform(Platform.Linux);
            } else {
                setPlatform(undefined); // Unknown platform
            }
        }
    }, []);

    return { platform, isMobileDevice: isMobileDevice() };
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeStatus from 'ui-kit/bagde/badge-status';
import Button from 'ui-kit/button/button';
import ClockCircleIcon from 'ui-kit/icons/clock-circle';
import MapPinIcon from 'ui-kit/icons/map-pin/map-pin-icon';
import PhoneIcon from 'ui-kit/icons/phone/phone';
import Link from 'ui-kit/link/link';

import { BREAKPOINTS } from 'const/breakpoints';

import { PharmacyInfo, PharmacyResults } from 'types/discount-card';

import { ellipsify } from 'util/cart';
import { convertToTitleCase } from 'util/string';

import { Platform, usePlatform } from 'hooks/usePlatform';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './pharmacy-details.styles.scss';

const PharmacyCard: React.FC<PharmacyInfo> = (props) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const { platform } = usePlatform();

    const handleEllipsis = (label: string, screenSize: number): string => {
        if (screenSize < BREAKPOINTS.sm && label.length > 15) {
            return ellipsify(convertToTitleCase(label), 15);
        } else return convertToTitleCase(label);
    };

    const handleClick = (event?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        if (props.onCardClick) {
            props.onCardClick({ ...props });
        }
    };

    const renderPharmacyLink = useMemo((): JSX.Element | null => {
        if (!platform) {
            return null;
        }

        const linkUrl =
            platform === Platform.MacOS
                ? `http://maps.apple.com/?daddr=${props?.Latitude},${props?.Longitude}`
                : `https://maps.google.com/?daddr=${props?.Latitude},${props?.Longitude}`;

        return (
            <Link
                to={linkUrl}
                label={props?.address}
                dataGALocation={`PharmacyAddress${props?.name}`}
                variant="underline-slate"
                className="address-link-external"
            />
        );
    }, [platform, props?.Latitude, props?.Longitude, props?.address, props?.name]);

    return (
        <div
            role="button"
            className={`${props.isSelected ? 'pharmacy-card selected' : 'pharmacy-card'}`}
            onClick={handleClick}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    handleClick(e);
                }
            }}
            tabIndex={0}
        >
            <div className="content">
                <div className="info">
                    <abbr title={props?.name}>
                        <h4>{handleEllipsis(props?.name, width)}</h4>
                    </abbr>

                    <div className="address">
                        <MapPinIcon />
                        {renderPharmacyLink}
                    </div>

                    {props?.phoneNumber && (
                        <div className="phone-number">
                            <PhoneIcon />
                            <Link
                                to={`tel:${props?.phoneNumber}`}
                                label={props?.phoneNumber}
                                dataGALocation={`ContactPhone${props?.name}`}
                                variant="underline-slate"
                                className="phone-number-link"
                            />
                        </div>
                    )}

                    {props?.schedules && (
                        <div className="schedules">
                            <ClockCircleIcon />
                            <p>{t(props?.schedules)}</p>
                        </div>
                    )}
                </div>

                {props?.price && (
                    <div className="price">
                        {props?.isLowestPrice && (
                            <BadgeStatus
                                variant="success"
                                label={t('pages.discountCard.pharmacyResults.pharmacyList.lowestPrice')}
                            />
                        )}
                        <p className={props?.isLowestPrice ? 'lowest-price' : 'normal-price'}>{props?.price}</p>
                    </div>
                )}
            </div>

            {props.price?.toString() === '$N/A*' && (
                <div className="note"> {t('pages.discountCard.pharmacyResults.pharmacyList.noPriceDisclaimer')}</div>
            )}
        </div>
    );
};

const PharmacyDetails: React.FC<
    PharmacyResults & { selectedCardId: string | null; isOpen: boolean; onGroupToggle: () => void }
> = ({
    name,
    price,
    resultsNumber,
    locations,
    isLowestPrice,
    onSelectedPharmacy,
    selectedCardId,
    isOpen,
    onGroupToggle
}) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const handleGroupToggle = () => {
        const isPharmacyAlreadySelected = locations.some((pharmacy) => pharmacy.NCPDP === selectedCardId);

        if (!isOpen && locations.length && onSelectedPharmacy && !isPharmacyAlreadySelected) {
            onSelectedPharmacy(locations[0]);
        }

        onGroupToggle();
    };

    const handleEllipsis = (label: string, screenSize: number): string => {
        if (screenSize < BREAKPOINTS.sm && label.length > 15) {
            return ellipsify(convertToTitleCase(label), 15);
        } else return convertToTitleCase(label);
    };

    return (
        <>
            {locations.length > 1 ? (
                <div className="pharmacy-detail">
                    <div className={`${'pharmacy-detail-content'} ${isOpen ? 'is-open' : ''}`}>
                        <div className="pharmacy">
                            <h4 className="label">{handleEllipsis(name, width)}</h4>
                            <p className="label">
                                {t('pages.discountCard.pharmacyResults.pharmacyDetails.resultsNumber', {
                                    number: resultsNumber
                                })}
                            </p>
                        </div>

                        <div className="price">
                            {isLowestPrice && <BadgeStatus variant="success" label="Lowest Price" />}
                            <p className={isLowestPrice ? 'lowest-price' : 'normal-price'}>{price}</p>
                        </div>

                        {price === '$N/A*' && (
                            <div className="note">
                                {t('pages.discountCard.pharmacyResults.pharmacyList.noPriceDisclaimer')}
                            </div>
                        )}
                    </div>

                    <div className={isOpen ? 'pharmacy-detail-list' : 'pharmacy-detail-list-no-display'}>
                        {isOpen &&
                            locations.map((pharmacy) => (
                                <PharmacyCard
                                    {...pharmacy}
                                    price={undefined}
                                    key={pharmacy.NCPDP}
                                    isSelected={selectedCardId === pharmacy.NCPDP}
                                    onCardClick={() => onSelectedPharmacy?.(pharmacy)}
                                />
                            ))}
                    </div>

                    <Button
                        label={
                            isOpen
                                ? t('pages.discountCard.pharmacyResults.pharmacyDetails.hideLocations')
                                : t('pages.discountCard.pharmacyResults.pharmacyDetails.showLocations')
                        }
                        onClick={handleGroupToggle}
                        className="show-location"
                        type="button"
                        variant="text-blue"
                        chevron={isOpen ? 'up' : 'down'}
                    />
                </div>
            ) : (
                locations.map((pharmacy) => (
                    <PharmacyCard
                        {...pharmacy}
                        key={pharmacy.NCPDP}
                        isSelected={selectedCardId === pharmacy.NCPDP}
                        onCardClick={() => onSelectedPharmacy?.(pharmacy)}
                        isLowestPrice={isLowestPrice}
                    />
                ))
            )}
        </>
    );
};

export default PharmacyDetails;

export { PharmacyCard };
